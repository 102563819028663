export function toQueryStringWithPrefix(params: Record<string, string>, prefix: string): string {
  return Object.keys(params).map(key => {
    const value: string = params[key]
    const paramKey: string = prefix ? `${prefix}[${key}]` : key

    if (typeof value === 'object') {
      return toQueryStringWithPrefix(value, paramKey)
    }

    return `${encodeURIComponent(paramKey)}=${encodeURIComponent(value)}`
  }).join('&')
}
