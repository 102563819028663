export function debounce(func: (...args: any[]) => void, wait: number): (this: any, ...args: any[]) => void {
  let timeout: number | undefined

  return function (this: any, ...args: any[]): void {
    clearTimeout(timeout)

    timeout = window.setTimeout(() => {
      func.apply(this, args)
    }, wait)
  }
}
