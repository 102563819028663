
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class AppArticleListViewFilters extends Vue {
  @Prop({
    type: Object
  }) filters!: Record<string, string>

  onInput(): void {
    this.$emit('on-input', this.filters)
  }
}
