import { THeader } from '@/app/article/shared/types'

const HEADERS: THeader[] = [
  { value: 'title', text: 'Наименование' },
  { value: 'section', text: 'Секция' },
  { value: 'isActive', text: 'Статус' },
  { value: 'updated', text: 'Дата изменения' },
  { value: 'actions', text: 'События', sortable: false }
]

const DEBOUNCE_PENDING = 500

const SORT_DIRECTIONS = {
  ASK: 'asc',
  DESC: 'desc'
}

export {
  HEADERS,
  DEBOUNCE_PENDING,
  SORT_DIRECTIONS
}
